import React from 'react';

const WalletSvg = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			version='1.1'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width='100%'
			height='100%'
			x='0'
			y='0'
			viewBox='0 0 3000 3000'
			xmlSpace='preserve'
			fillRule='evenodd'
		>
			<g>
				<circle
					cx='1500'
					cy='1500'
					r='1500'
					fill='#f38f41'
					opacity='1'
					data-original='#f38f41'
				></circle>
				<g fill='#fff'>
					<path
						d='M1569.91 2294.75H703.36c-31.25 0-56.69-25.43-56.69-56.69v-335.34h979.92v335.35c-.01 31.24-25.43 56.68-56.69 56.68zm-592.7-241.96H812.15c-35.11 0-35.11-53.33 0-53.33h165.06c35.11 0 35.11 53.33 0 53.33zm0 108.79H812.15c-35.11 0-35.11-53.33 0-53.33h165.06c35.11 0 35.11 53.33 0 53.33zm-330.53-312.19v-107.96h979.92v107.96zm0-161.29v-50.24c0-31.25 25.43-56.68 56.69-56.68h866.55c31.25 0 56.69 25.43 56.69 56.69v50.24H646.69z'
						fill='#ffffff'
						opacity='1'
						data-original='#ffffff'
					></path>
					<path
						d='M2251.65 2187.82h-591.73v-53.32h591.73c26.67 0 48.35-21.7 48.35-48.35V806.94c0-26.65-21.7-48.35-48.35-48.35h-724.01c-26.65 0-48.35 21.7-48.35 48.35v740.91h-53.34V806.94c0-56.07 45.61-101.68 101.68-101.68h724.02c56.07 0 101.68 45.61 101.68 101.68v1279.2c0 56.07-45.61 101.68-101.68 101.68z'
						fill='#ffffff'
						opacity='1'
						data-original='#ffffff'
					></path>
					<path
						d='M2082.85 908.64h-386.39c-31.25 0-56.67-25.43-56.67-56.68V731.92c0-14.74 11.93-26.67 26.67-26.67h446.4c14.74 0 26.67 11.93 26.67 26.67v120.04c-.01 31.26-25.43 56.68-56.68 56.68zM2007.82 2077.18h-236.33c-35.11 0-35.11-53.33 0-53.33h236.33c35.12 0 35.1 53.33 0 53.33zM1923.82 1549.56l149.12-84.99-149.12-64.13zM1867.94 1549.56v-149.12l-149.13 64.13zM1722.53 1402.15l145.41-62.54V1150.3zM2069.23 1402.15l-145.41-251.85v189.31zM1922.55 1819.42l136.91-199.05-136.91 78.54zM1869.21 1819.36v-121.28l-136.82-78.25z'
						fill='#ffffff'
						opacity='1'
						data-original='#ffffff'
					></path>
				</g>
			</g>
		</svg>
	);
};

export default WalletSvg;
